/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { SecretDesire } from '@/store/secretDesire/types'
import { arrow } from '@popperjs/core'

const namespace: string = 'secretDesire';

export default class SecretDesireModelChoicePage extends Vue {
	@State('secretDesire') secretDesire: SecretDesire | undefined;
	@Action('fetchSecretDesireModels', {namespace}) fetchSecretDesireModels: any;
	@Getter('getArrAllModels', {namespace}) getArrAllModels: any;
	@Mutation('setSelectedModels', {namespace}) setSelectedModels: any;

	modelProfile: Array<boolean> = [];
	arrSelectedModels: Array<any> = [];

	selectedModels() {
		this.arrSelectedModels = this.getArrAllModels.filter((item: any, i: number) => item.login === this.modelProfile[i])
	}
	onSubmit() {
		this.selectedModels();
		this.setSelectedModels(this.arrSelectedModels);
		this.$router.push({ name: 'secret-desire-request' }).then(r => r);
	}

	created() {
		this.fetchSecretDesireModels();
	}
}
